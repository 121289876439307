<template>
    <div id="contents" class="contents card_add page" v-if="cards">
        <div class="content_head">
            <!-- s: 페이지 타이틀 -->
            <div class="page_ttl_wrap has_btn">
                <h2 class="page_ttl">제휴카드</h2>
                <div class="btn_group">
                    <span>최근 발행일시 {{recentPublishTime ? moment(recentPublishTime).format('YYYY.MM.DD HH:mm:ss') : '없음'}}</span>
                    <!-- Dev: 변경사항이 없을시 비활성화 -->
                    <button type="button" class="btn strong2 w_m h_l" @click="publish">발행하기</button>
                </div>
            </div>
        </div>
        <!-- e: 페이지 타이틀 -->
        <!-- s: content_body -->
        <div class="content_body">
            <!-- s: item_list_sec-->
            <div class="item_list_sec">
                <!-- Dev: 카드 추가 wrap -->
                <div class="item_list_wrap none min" :class="{is_active : clickedCardHash == null}"
                     @click="clickAddBtn()">
                    <div class="item_list_head min">
                        <div class="item_thumb">
                            <button>추가버튼</button>
                        </div>
                    </div>
                </div>
                <!-- Dev : 등록된 카드  -->
                <div class="registration_item_wrap">
                    <Container class="registration_item_area"
                               orientation="horizontal"
                               tag="div"
                               :drop-placeholder="dropPlaceholderOptions"
                               @drop="onDrop">
                        <Draggable class="item_list_wrap min" v-for="(item, index) in cards" :key="'item' + item.hash"
                                   :class="{is_active : item.hash === clickedCardHash}" @click="clickCard(item, item.hash)">
                            <!--                            <div style="height: 20px;font-size: 8pt">{{item.hash}}</div>-->
                            <div class="item_list_head min">
                                <div class="item_thumb" :style="`background-image: url(${getFile(item.cardImage)})`">
                                </div>
                                <div class="delete_btn_area">
                                    <button class="delete_btn" @click="removeList(item.hash)">삭제버튼</button>
                                </div>
                            </div>
                            <div class="item_list_body">
                                {{item.file}}
                            </div>
                        </Draggable>
                    </Container>
                </div>
            </div>
            <!-- e: item_list_sec-->

            <!-- s: preview_sec -->
            <div class="preview_sec">
                <div class="preview_wrap">
                    <!-- Dev : psd 다운로드 버튼 추가 -->
                    <a class="psd_down_btn" :href="`${publicPath}file/card.zip`" download="">
                        PSD 다운로드
                    </a>
                    <div class="upload_guide" role="button">
                        <div class="tooltip_area">
                            <img src="../../../../assets/img/common/tooltip.png" alt="">
                            <p>파일 업로드 가이드</p>
                        </div>
                        <div class="layer_info">
                            <div class="info_area" v-for="(item, i) in tooltip" :key="'item' + i">
                                <div class="ttl">{{item.ttl}}</div>
                                <div class="cnt">
                                    <p>{{item.file}}</p>
                                    <p>{{item.memory}}</p>
                                    <p>{{item.display}}</p>
                                </div>
                            </div>
                          <p class="info_txt">※ 파일명은 영어로 등록해주세요.</p>
                        </div>
                    </div>
                    <div class="preview_area" v-if="clickedCard">
                        <!-- Dev s: 등록된 파일 없을 경우 -->
                        <template v-if="!clickedCard.cardDescImage">
                            <div class="info_area_card">
                                <div class="upload_btn" role="button">
                                    <div class="txt_area">
                                        <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                                    </div>
                                    <div class="btn_area">
                                        <button type="button" class="btn basic w_smm h_m">파일 추가</button>
                                        <input type="file" accept="image/png"
                                               @change="onFileChange($event, 'cardDescImage')">
                                    </div>
                                    <div class="info_area">
                                        <p>png, 1840*433</p>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <!-- Dev e: 등록된 파일 없을 경우 -->

                        <!-- Dev s: 등록된 파일 있을 경우 -->
                        <template v-else>
                            <div class="info_area_card exist">
                                <div class="info_box"
                                     :style="`background-image: url(${getFile(clickedCard.cardDescImage)})`">
                                    <template v-if="clickedCard.cardDescImage">
                                        <div class="hover_btn_area">
                                            <!--  Dev: 파일 수정 버튼 클릭시 파일첨부 호출 -->
                                            <button class="modify_btn">
                                                <img src="../../../../assets/img/common/video_modify.png" alt="">
                                                <input type="file" accept="image/png"
                                                       @change="onFileChange($event, 'cardDescImage')">
                                            </button>
                                            <!--  Dev: 파일 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                            <button class="delete_btn" @click="deleteImage('cardDescImage')">삭제</button>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </template>
                        <!-- Dev e: 등록된 파일 있을 경우 -->

                        <!-- Dev s: 등록된 파일 없을 경우 -->
                        <template v-if="!clickedCard.cardImage">
                            <div class="img_area_card">
                                <div class="upload_btn" role="button">
                                    <div class="txt_area">
                                        <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                                    </div>
                                    <div class="btn_area">
                                        <button type="button" class="btn basic w_smm h_m">파일 추가</button>
                                        <input type="file" accept="image/png, image/jpg"
                                               @change="onFileChange($event, 'cardImage')">
                                    </div>
                                    <div class="info_area">
                                        <p>png/jpg 540*342</p>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <!-- Dev e: 등록된 파일 없을 경우 -->

                        <!-- Dev s: 등록된 파일 있을 경우 -->
                        <template v-else>
                            <div class="img_area_card exist">
                                <div class="img_box"
                                     :style="`background-image: url(${getFile(clickedCard.cardImage)})`">
                                    <template v-if="clickedCard.cardImage">
                                        <div class="hover_btn_area">
                                            <!--  Dev: 파일 수정 버튼 클릭시 파일첨부 호출 -->
                                            <button class="modify_btn">
                                                <img src="../../../../assets/img/common/video_modify.png" alt="">
                                                <input type="file" accept="image/png"
                                                       @change="onFileChange($event, 'cardImage')">
                                            </button>
                                            <!--  Dev: 파일 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                            <button class="delete_btn" @click="deleteImage('cardImage')">삭제</button>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </template>
                        <!-- Dev e: 등록된 파일 없을 경우 -->
                        <div class="qr_area">
                            <div class="qr_box"
                                 :class="{'has_img' : clickedCard.qrImage}"
                                 :style="clickedCard.qrImage ? `background-image: url(${getFile(clickedCard.qrImage)})` : ''">
                                <!-- Dev s: 등록된 파일 없을 경우 -->
                                <button v-if="!clickedCard.qrImage">
                                    qr 추가
                                    <input type="file" accept="image/gif,image/jpeg,image/png"
                                           @change="onFileChange($event, 'qrImage')">
                                </button>
                                <!-- Dev e: 등록된 파일 없을 경우 -->
                            </div>
                            <!--  Dev: 파일이 있을 경우에만 hover_btn_area 출력 -->
                            <template v-if="clickedCard.qrImage">
                                <div class="hover_btn_area">
                                    <!--  Dev: 파일 수정 버튼 클릭시 파일첨부 호출 -->
                                    <button class="modify_btn">
                                        <img src="../../../../assets/img/common/video_modify.png" alt="">
                                        <input type="file" accept="image/gif,image/jpeg,image/png"
                                               @change="onFileChange($event, 'qrImage')">
                                    </button>
                                    <!--  Dev: 파일 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                    <button class="delete_btn" @click="deleteImage('qrImage')">삭제</button>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <!-- e: preview_sec -->
        </div>
        <!-- e: content_body -->

        <!-- s: content_foot -->
        <div class="content_foot">
            <div class="btn_group center">
                <!-- Dev : 첨부파일에 대한 부분만 리셋        -->
<!--                <button v-if="!clickedCardHash" class="btn basic w_m h_l" @click="resetCard">취소</button>-->
                <!-- Dev : 클릭 시 메인 영상 등록: 파일 첨부 반영  -->
                <button v-if="!clickedCardHash" class="btn strong w_m h_l" @click="createCard">저장</button>
                <button v-else class="btn strong w_m h_l" @click="editCard">저장</button>
            </div>
        </div>
        <!-- e : content_foot  -->
    </div>
    <Dialog :isOpen="deleteDialogState" :yes="'삭제'" :cancel="'취소'" v-on:cancelDialog="deleteDialogState = false"
            @yesDialog="deleteCard()">
        <template #body>
            <h3>삭제 확인</h3>
            <p>선택한 카드를 삭제하시겠습니까?<br>
                변경된 내용은 “발행하기”를 진행해야 체험존에 반영됩니다</p>
        </template>
    </Dialog>

    <!-- Dev :  페이지 나가기, 새로고침, 브라우저 닫기 시 호출 -->
    <Dialog :isOpen="pageOutDialogState" :yes="'삭제'" :cancel="'취소'" v-on:cancelDialog="pageOutDialogState = false"
            @yesDialog="pageOut()">
        <template #body>
            <h3>페이지에서 나가시겠습니까?</h3>
            <p>변경된 내용은 “발행하기”를 진행해야 체험존에 반영됩니다.</p>
        </template>
    </Dialog>
</template>

<script>
import {Container, Draggable} from "vue3-smooth-dnd";
import moment from "moment";
import Dialog from "@/components/dialog";
import Card from "@/plugins/firebase/model/card";
import {applyDrag} from "@/plugins/dnd-helpers";

export default {
    name: "new-index",
    layout: 'new-admin',
    components: {
        Container: Container,
        Draggable: Draggable,
        Dialog,
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            moment: moment,
            dropPlaceholderOptions: {
                className: 'drop-preview',
                animationDuration: '150',
                showOnTop: true
            },
            imgFile: true,
            isActive: false,
            deleteDialogState: false,
            pageOutDialogState: false,
            tooltip: [
                {
                    ttl: '카드 이미지',
                    file: 'PNG, JPG',
                    memory: '용량 1MB 이하',
                    display: '해상도 540*342',
                },
                {
                    ttl: '카드 설명 이미지',
                    file: 'PNG',
                    memory: '용량 1MB 이하',
                    display: '해상도 1840*433',
                },
                {
                    ttl: 'QR 이미지',
                    file: 'PNG',
                    memory: '용량 1MB 이하',
                    display: '해상도 74*74',
                },
            ],

            submenu_card_publish_key: 'publish/submenu/card',
            submenu_card_root_key: 'submenu/card',
            recentPublishTime: '',
            cards: null,
            originalCards: {},
            clickedCard: new Card(),
            clickedCardHash: null,
            deleteCardHash: null,
            canLeaveSite: true,
            isUploading: false,
        }
    },
    methods: {
        async publish() {
            const time = this.timeToString(moment());
            await this.$firebase.database().ref(this.submenu_card_publish_key).child('lastPublishDateTime').set(time);
            this.toastSuccess('발행 완료.');
        },
        onFileChange(e, key) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            let file = files[0];

            const isImage = this.isImage(file);
            const maxImageSize = 2 * 1024 * 1024;
            if (isImage) {
                if (maxImageSize <= file.size) {
                    this.toastDanger('이미지 용량은 2MB 이하로 등록해주세요.');
                    return;
                }
            } else {
                this.toastDanger('PNG, JPG 이미지 파일만 가능합니다.');
                return;
            }

            this.clickedCard[key] = file;
            this.canLeaveSite = false;
        },
        onDrop(dropResult) {
            if (dropResult.removedIndex !== null && dropResult.addedIndex !== null) {
                this.cards[dropResult.removedIndex].order = dropResult.addedIndex + 1;
                this.cards[dropResult.addedIndex].order = dropResult.removedIndex + 1;

                /*const removed = this.cards[dropResult.removedIndex];
                const added = this.cards[dropResult.addedIndex];
                this.$firebase.database().ref(this.submenu_card_root_key).child(removed.hash).set(removed);
                this.$firebase.database().ref(this.submenu_card_root_key).child(added.hash).set(added);
                this.clickedCard = added;
                this.clickedCardHash = added.hash;*/
            }
            this.cards = applyDrag(this.cards, dropResult);
            this.cards.forEach((item, index) => {
                item.order = index + 1;
            });
            this.cards = this.cards.sort((a, b) => {
                return a.order - b.order;
            });
            console.log(this.cards)
            this.cards.forEach((item) => {
                this.$firebase.database().ref(this.submenu_card_root_key).child(item.hash).set(item);
            });
            const clickCard = this.cards[dropResult.addedIndex];
            this.clickCard(clickCard, clickCard.hash);
            this.canLeaveSite = false;
        },
        async deleteCard() {
            this.deleteDialogState = false;
          // 2022-01-25 파일 삭제 임시 주석
            //await this.deleteFolderContents(this.deleteCardHash);
            this.$firebase.database().ref(this.submenu_card_root_key).child(this.deleteCardHash).remove().then(async () => {
                this.deleteCardHash = null;
                this.toastSuccess('카드가 삭제 되었습니다.');
                this.reOrdering();
                this.reloadData().then(() => {
                    if(this.cards && this.cards.length > 0){
                        const card = this.mainVideos[0];
                        this.clickCard(card, card.hash);
                    }else{
                        this.clickAddBtn();
                    }
                });
            });
            this.canLeaveSite = false;
        },
        removeList(hash) {
            this.deleteCardHash = hash;
            this.deleteDialogState = true;
        },
        pageOut() {
            this.pageOutDialogState = false;
        },

        clickAddBtn() {
            this.clickedCard = new Card();
            this.clickedCardHash = null;
        },
        clickCard(item, hash) {
            const prevHash = this.clickedCard.hasOwnProperty('hash') ? this.clickedCard.hash : null;
            if(prevHash && this.originalCards.hasOwnProperty(prevHash)){
                const original = this.originalCards[prevHash];
                const find = this.cards.findIndex((item) => item.hash === prevHash);
                if(find > -1){
                    this.cards[find] = JSON.parse(JSON.stringify(original));
                }
            }
            this.clickedCard = item;
            this.clickedCardHash = hash;
        },
        async createCard() {
            if(this.isUploading){
                return;
            }

            const card = this.clickedCard;
            if (!this.isImage(card.cardImage)) {
                this.toastDanger('카드 이미지를 등록해주세요.');
                return;
            }
            if (!this.isImage(card.cardDescImage)) {
                this.toastDanger('카드 설명 이미지를 등록해주세요.');
                return;
            }
           /* if (!this.isImage(card.qrImage)) {
                this.toastDanger('QR 이미지를 등록해주세요.');
                return;
            }*/
            this.isUploading = true;
            this.loadingOverlay(async (loader) => {
                card.order = 1;
                for await (const item of this.cards) {
                    const index = this.cards.indexOf(item);
                    item.order = index + 2;
                    await this.$firebase.database().ref(this.submenu_card_root_key).child(item.hash).set(item);
                }
                const hash = this.$firebase.database().ref(this.submenu_card_root_key).push(card).key;
                const files = card.qrImage ? [card.cardImage, card.cardDescImage, card.qrImage] : [card.cardImage, card.cardDescImage];
                let fileLinks = [];
                card.creationDateTime = this.timeToString(moment());
                this.$Progress.start();
                for await (const file of files) {
                    if (file && file instanceof File) {
                        const index = files.indexOf(file);
                        let downloadUrl = await this.upload(hash, file, index, files.length);
                        fileLinks[index] = downloadUrl;
                    }
                }
                card.hash = hash;
                card.cardImage = fileLinks[0];
                card.cardDescImage = fileLinks[1];
                if(card.qrImage) card.qrImage = fileLinks[2];
                this.$Progress.finish();
                card.lastModifiedDateTime = this.timeToString(moment());
                await this.$firebase.database().ref(this.submenu_card_root_key).child(hash).set(card).then(() => {
                    this.toastSuccess('새 카드 등록되었습니다.');
                    this.clickedCardHash = hash;
                    this.reloadData().then((data) => {
                        if(data){
                            const cards = data.val();
                            if(cards){
                                for(const [hash, card] of Object.entries(cards)){
                                    card.hash = hash;
                                    this.originalCards[hash] = JSON.parse(JSON.stringify(card));
                                }
                            }
                        }
                        this.clickCard(card, hash);
                    });
                });
                this.canLeaveSite = false;

                this.isUploading = false;
                loader.hide();
            });
        },
        async editCard() {
          if(this.isUploading){
            return;
          }

          const card = this.clickedCard;
            if (!this.isImage(card.cardImage)) {
                this.toastDanger('카드 이미지를 등록해주세요.');
                return;
            }
            if (!this.isImage(card.cardDescImage)) {
                this.toastDanger('카드 설명 이미지를 등록해주세요.');
                return;
            }
            /*if (!this.isImage(card.qrImage)) {
                this.toastDanger('QR 이미지를 등록해주세요.');
                return;
            }*/
          this.isUploading = true;
          this.loadingOverlay(async (loader) => {
            const hash = this.clickedCardHash;
            const files = card.qrImage ? [card.cardImage, card.cardDescImage, card.qrImage] : [card.cardImage, card.cardDescImage];
            let fileLinks = [];
            card.creationDateTime = this.timeToString(moment());
            this.$Progress.start();
            for await (const file of files) {
              if (file && file instanceof File) {
                const index = files.indexOf(file);
                let downloadUrl = await this.upload(hash, file, index, files.length);
                fileLinks[index] = downloadUrl;
              }
            }
            card.cardImage = fileLinks[0] ? fileLinks[0] : card.cardImage;
            card.cardDescImage = fileLinks[1] ? fileLinks[1] : card.cardDescImage;
            card.qrImage = fileLinks[2] ? fileLinks[2] : card.qrImage;
            this.$Progress.finish();
            card.lastModifiedDateTime = this.timeToString(moment());
            await this.$firebase.database().ref(this.submenu_card_root_key).child(hash).set(card).then(() => {
              this.toastSuccess('카드가 수정되었습니다.');
              this.clickedCardHash = hash;
              this.reloadData().then((data) => {
                if (data) {
                  const cards = data.val();
                  if (cards) {
                    for (const [hash, card] of Object.entries(cards)) {
                      card.hash = hash;
                      this.originalCards[hash] = JSON.parse(JSON.stringify(card));
                    }
                  }
                }
              });
            });
            this.canLeaveSite = false;
            this.isUploading = false;
            loader.hide();
          });
        },
        async upload(hash, image, current, total) {
            let storageRef = this.$firebase.storage().ref();
            let imageRef = storageRef.child(hash).child(image.name);
            let uploadTask = imageRef.put(image);
            return new Promise(((resolve, reject) => {
                uploadTask.on('state_changed', (snapshot) => {
                    let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    const gage = (Math.floor(100 / total) * current) + (Math.floor(progress) / total);
                    this.$Progress.set(gage);
                    //console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case this.$firebase.storage.TaskState.PAUSED: // or 'paused'
                            //console.log('Upload is paused');
                            break;
                        case this.$firebase.storage.TaskState.RUNNING: // or 'running'
                            //console.log('Upload is running');
                            break;
                    }
                }, (error) => {
                    console.error('[ERROR] state_changed', error);
                    reject(error);
                }, () => {
                    // 완료 다운로드 예시
                    resolve(uploadTask.snapshot.ref.getDownloadURL());
                });
            }));
        },
        deleteImage(key) {
            this.clickedCard[key] = '';
            this.canLeaveSite = false;
        },
        reOrdering() {
            this.$firebase.database().ref(this.submenu_card_root_key).once('value', (sn) => {
                const cards = sn.val() || {};
                let temps = [];
                for (const [hash, card] of Object.entries(cards)) {
                    card.hash = hash;
                    temps.push(card);
                }
                temps = temps.sort((a, b) => a.order - b.order);
                for (const [index, card] of temps.entries()) {
                    card.order = index + 1;
                    const hash = card.hash;
                    // delete card.hash;
                    this.$firebase.database().ref(this.submenu_card_root_key).child(hash).set(card);
                }
            });
            this.canLeaveSite = false;
        },
        reloadData() {
            this.$firebase.database().ref(this.submenu_card_publish_key).once('value', (sn) => {
                const publish = sn.val() || {};
                if (publish) {
                    this.recentPublishTime = publish.lastPublishDateTime;
                }
            });
            return this.$firebase.database().ref(this.submenu_card_root_key).once('value', (sn) => {
                const cards = sn.val() || {};
                const cardArr = [];
                for (const [hash, card] of Object.entries(cards)) {
                    card.hash = hash;
                    cardArr.push(card);
                }
                this.cards = cardArr.sort((a, b) => a.order - b.order);
            });
        },
        deleteFolderContents(path) {
            const ref = this.$firebase.storage().ref(path);
            return ref.listAll()
                    .then(dir => {
                        dir.items.forEach(fileRef => {
                            this.deleteFile(ref.fullPath, fileRef.name);
                        });
                        dir.prefixes.forEach(folderRef => {
                            this.deleteFolderContents(folderRef.fullPath);
                        })
                    })
                    .catch(error => {
                        console.log(error);
                    });
        },
        deleteFile(pathToFile, fileName) {
            const ref = this.$firebase.storage().ref(pathToFile);
            const childRef = ref.child(fileName);
            childRef.delete();
            this.canLeaveSite = false;
        },
        resetCard() {
            this.clickedCard.cardImage = '';
            this.clickedCard.cardDescImage = '';
            this.clickedCard.qrImage = '';
        }
    },
    created() {
        this.$firebase.database().ref(this.submenu_card_publish_key).on('value', (sn) => {
            const publish = sn.val() || {};
            if (publish) {
                this.recentPublishTime = publish.lastPublishDateTime;
            }
        });

        this.reloadData().then((data) => {
            if(data){
                const cards = data.val();
                if(cards){
                    for(const [hash, card] of Object.entries(cards)){
                        card.hash = hash;
                        this.originalCards[hash] = JSON.parse(JSON.stringify(card));
                    }
                }
            }
            if(this.cards && this.cards.length > 0){
                const card = this.cards[0];
                this.clickCard(card, card.hash);
            }else{
                this.clickAddBtn();
            }
        });
        /*this.$firebase.database().ref(this.submenu_hotdeal_root_key).once('value', (sn) => {
            const submenu = sn.val() || {};
            if(submenu){
                this.imgFile = submenu.imgFile;
                this.previewUrl = submenu.previewUrl;
                this.iframeUrl = submenu.previewUrl;
            }
        })*/
    },
    mounted() {
        window.addEventListener('beforeunload', this.unLoadEvent);
    },
    beforeUnmount() {
        window.removeEventListener('beforeunload', this.unLoadEvent);
    },
    beforeRouteLeave(to, from, next) {
        if (this.canLeaveSite) next();
        else if (confirm('이 사이트에서 나가시겠습니까?\n변경사항이 저장되지 않을 수 있습니다.')) {
            next();
        }
    }
}
</script>

<style scoped>

</style>
